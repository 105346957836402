import axios from "axios"

const calculatePrice = function (data) {
    const { user, storage, live } = data
    return new Promise((resolve, reject) => {
        axios.get(`/company/calculate?user=${user}&storage=${storage}&live=${live}`).then(resp => {

            if (resp.data.status == true) {
                resolve(resp.data.result)
            } else {
                let result = {
                    user: Number(user),
                    storage: Number(storage),
                    live: Number(live)
                }
                result.tax = 0
                result.price = 0
                result.priceIncTax = 0
                resolve(result)
            }
        }).catch(err => {
            reject(err)
        })
    })
}

export { calculatePrice }