<template>
  <div>
    <section class="bg-half bg-light d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title">Choose Your Payment</h4>

              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-white rounded shadow mb-0">
                    <li class="breadcrumb-item">
                      <router-link to="/">Landrick</router-link>
                    </li>
                    <li class="breadcrumb-item">
                      <router-link to="/index-shop">Shop</router-link>
                    </li>
                    <li class="breadcrumb-item">
                      <router-link :to="{ name: 'Summary', query: $route.query }">Cart</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Payment</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor" />
        </svg>
      </div>
    </div>

    <section class="mt-4 mb-4">
      <div class="container">
        <div class="container-xl container-fluid">
          <div class="rounded shadow p-4">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="mb-0">Payment Methods</h5>
            </div>
            <div class="pt-2">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <div class="custom-control custom-radio custom-control-inline">
                    <div class="form-group mb-0">
                      <input
                        type="radio"
                        id="creditCardMethod"
                        name="creditCardMethod"
                        class="custom-control-input"
                        v-model="paymentMethod"
                        value="credit_card"
                      />
                      <label for="creditCardMethod" class="custom-control-label">Credit Card</label>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="d-flex">
                    <div class="pl-1">
                      <img
                        src="images/payments/58482363cef1014c0b5e49c1.png"
                        height="24"
                        class="text-right"
                        alt
                      />
                    </div>
                    <div class="pl-1">
                      <img
                        src="images/payments/payment/master.png"
                        height="24"
                        class="text-right"
                        alt
                      />
                    </div>
                  </div>
                </div>
              </div>
              <template v-if="paymentMethod == 'credit_card'">
                <div class="pl-4">
                  <div>
                    <button class="btn btn-light btn-sm">Manage Cards</button>
                  </div>
                  <div class="p-3 mt-3 rounded bg-light bg-gradient">
                    <div class="text-muted mb-2">Choose card or using new card</div>

                    <div class="row no-gutters">
                      <template v-for="(card, idx) of customerCards">
                        <div class="col-12 py-1" :key="`customer_card_${idx}`">
                          <div class="row no-gutters">
                            <div class="col-3">
                              <div class="custom-control custom-radio custom-control-inline">
                                <div class="form-group mb-0">
                                  <input
                                    type="radio"
                                    :id="`exist_card_${idx}`"
                                    :name="`exist_card_${idx}`"
                                    class="custom-control-input"
                                    :value="card.id"
                                    v-model="selectedCard"
                                  />
                                  <label
                                    :for="`exist_card_${idx}`"
                                    class="custom-control-label"
                                  >{{ card.name }}</label>
                                </div>
                              </div>
                            </div>
                            <div class="col-9">
                              {{ card.brand }}
                              **** {{ card.last_digits }} (expires
                              {{
                              card.expiration_month.toString().padStart(2, 0)
                              }}/{{ card.expiration_year }})
                            </div>
                          </div>
                        </div>
                      </template>

                      <div class="col-12 py-1">
                        <div class="row no-gutters">
                          <div class="col-12">
                            <div class="custom-control custom-radio custom-control-inline">
                              <div class="form-group mb-0">
                                <input
                                  type="radio"
                                  id="new_card"
                                  name="new_card"
                                  class="custom-control-input"
                                  :value="0"
                                  v-model="selectedCard"
                                />
                                <label for="new_card" class="custom-control-label">Use a new card</label>
                              </div>
                            </div>

                            <template v-if="selectedCard == 0">
                              <credit-card-form
                                ref="creditCardForm"
                                :is-mobile="false"
                                :force-save="false"
                              ></credit-card-form>
                            </template>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 pt-3">
                        <div class="d-flex align-items-center">
                          <div>
                            <button
                              class="btn btn-primary"
                              @click="processPayment"
                            >Pay {{ totalAmount | numFormat("0,0.00") }} THB</button>
                          </div>
                          <!-- <div class="ml-4">
                            <span class="text-muted">Secured by</span>
                            <b-img
                              class="ml-2"
                              src="~@/assets/imgs/omise-logo.svg"
                            ></b-img>
                          </div>-->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <div class="d-flex align-items-center pt-4">
                <div class="flex-grow-1">
                  <div class="custom-control custom-radio custom-control-inline">
                    <div class="form-group mb-0">
                      <input
                        disabled
                        type="radio"
                        id="trueWalletMethod"
                        name="trueWalletMethod"
                        class="custom-control-input"
                        v-model="paymentMethod"
                        value="true-money-wallet"
                      />
                      <label for="trueWalletMethod" class="custom-control-label">True Money Wallet</label>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="d-flex">
                    <div class="pl-1">
                      <img
                        src="images/payments/Truemoney-wallet_thai.png"
                        height="32"
                        class="text-right"
                        alt
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex align-items-center pt-4">
                <div class="flex-grow-1">
                  <div class="custom-control custom-radio custom-control-inline">
                    <div class="form-group mb-0">
                      <input
                        disabled
                        type="radio"
                        id="internetBankingMethod"
                        name="internetBankingMethod"
                        class="custom-control-input"
                        v-model="paymentMethod"
                        value="internet-banking"
                      />
                      <label
                        for="internetBankingMethod"
                        class="custom-control-label"
                      >Internet Banking</label>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="d-flex">
                    <div class="pl-1">
                      <div class="rounded p-1" style="background-color: #4e2e7f">
                        <img src="images/payments/banks/scb.svg" height="24" alt />
                      </div>
                    </div>
                    <div class="pl-1">
                      <div class="rounded p-1" style="background-color: #1ba5e1">
                        <img src="images/payments/banks/ktb.svg" height="24" alt />
                      </div>
                    </div>
                    <div class="pl-1">
                      <div class="rounded p-1" style="background-color: #1e4598">
                        <img src="images/payments/banks/bbl.svg" height="24" alt />
                      </div>
                    </div>
                    <div class="pl-1">
                      <div class="rounded p-1" style="background-color: #fec43b">
                        <img src="images/payments/banks/bay.svg" height="24" alt />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <b-modal
      id="processScreen"
      title="Proceed to Payment"
      centered
      hide-footer
      hide-header-close
      title-class="text-center mx-auto"
    >
      <div class="d-flex flex-column justify-content-center align-items-center py-4">
        <!-- <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>-->
        <b-icon icon="stopwatch" font-scale="3" animation="cylon" variant="primary"></b-icon>
        <div class="h4 mt-4">Please wait a moment</div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import EventBus from "@/event-bus";
import CreditCardForm from "@/components/credit-card-form";
import { calculatePrice } from "@/services/calculatorService";
import omiseService from "@/services/omiseService";

export default {
  components: { CreditCardForm },
  data: () => ({
    paymentMethod: "credit_card",
    selectedCard: 0,
    totalAmount: 0,
    isSaveCard: false,
    spec: {},
    customerCards: [],
    paginationOpts: null
  }),
  mounted() {
    this.getCustomerCards();
    calculatePrice({
      user: this.$route.query.user,
      storage: this.$route.query.storage,
      live: this.$route.query.live
    })
      .then(priceInfo => {
        this.totalAmount = priceInfo.priceIncTax;
        this.spec = priceInfo;
      })
      .catch(err => {});
  },
  methods: {
    getCustomerCards() {
      omiseService
        .listCards()
        .then(resp => {
          if (resp.data.status == true && resp.data.result != null) {
            this.customerCards = resp.data.result.data;
            delete resp.data.result.data;
            this.paginationOpts = resp.data.result;

            // if (this.paginationOpts.total > 0) {
            //   // set to latest card
            //   this.selectedCard = this.customerCards[
            //     this.paginationOpts.total - 1
            //   ].id;
            // } else {
            //   this.selectedCard = 0;
            // }
          } else {
            throw "Can not get customer's cards";
          }
        })
        .catch(err => {
          this.customerCards = [];
        });
    },
    processPayment() {
      if (this.selectedCard == 0) {
        let newCard = this.$refs.creditCardForm.getCardInfo();
        if (newCard != null) {
          this.isSaveCard = newCard.saveCard;
          this.createOmiseToken(newCard);
        }
      } else {
        this.useExistCard();
      }
    },
    createOmiseToken(newCard) {
      if (this.paymentMethod == "credit_card") {
        let expires = newCard.expires.split("/");
        let prefixYear = new Date()
          .getFullYear()
          .toString()
          .substr(0, 2);
        let fullYear = `${prefixYear}${expires[1]}`;
        let tokenParameters = {
          expiration_month: +expires[0],
          expiration_year: +fullYear,
          name: newCard.holderName,
          number: newCard.cardNo.replace(/-|\s/g, ""),
          security_code: +newCard.secureCode
        };
        Omise.createToken("card", tokenParameters, this.createTokenCallback);
      }
    },
    createTokenCallback(statusCode, response) {
      if (response.object == "error") {
        this.$bvModal.msgBoxOk(response.message, {
          title: "Payment Service Error",
          size: "sm",
          okVariant: "danger",
          // headerBgVariant: "danger",
          // headerTextVariant: "light",
          // headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true
        });
      } else {
        // send ajax to charge
        this.chargeMoney({ token: response.id });
      }
    },
    useExistCard() {
      this.chargeMoney({ card: this.selectedCard });
    },
    chargeErrorMessage(message) {
      this.$bvModal
        .msgBoxOk(message, {
          title: "Can not charge payment",
          size: "sm",
          okVariant: "danger",
          // headerBgVariant: "danger",
          // headerTextVariant: "light",
          // headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true
        })
        .then(() => {
          this.$refs.creditCardForm.clear();
          this.getCustomerCards();
        });
    },
    chargeMoney(data) {
      const { token, card } = data;
      this.$bvModal.show("processScreen");
      let params = {
        tokenId: token || null,
        card: card || null,
        user: this.spec.user,
        storage: this.spec.storage,
        live: this.spec.live,
        saveCard: this.isSaveCard
      };
      omiseService
        .charge(params)
        .then(resp => {
          let statusPaid = resp.data.result.paid || false;
          // paid and no failured
          if (statusPaid == true && resp.data.result.failureCode == null) {
            let obj = {
              user: this.$route.query.user || 0,
              storage: this.$route.query.storage || 0,
              live: this.$route.query.live || 0
            };
            this.$store.commit("setBuyConfig", obj);
            this.$router.replace({
              name: "Finish",
              params: {
                ref: resp.data.result.chargeId
              }
            });
          } else {
            // has faillured

            this.chargeErrorMessage(
              `${resp.data.result.failureCode}: ${resp.data.result.failureMessage}`
            );
          }
        })
        .catch(err => {
          let defaultMsg = "Something went wrong! can not charge payment.";
          let msg = defaultMsg;
          if (err.response) {
            msg = err.response.data.message || defaultMsg;
          }
          this.chargeErrorMessage(msg);
        })
        .finally(() => {
          this.$bvModal.hide("processScreen");
          this.isSaveCard = false;
        });
    },
    openOmise() {
      OmiseCard.open({
        amount: 12345,
        currency: "THB",
        defaultPaymentMethod: "credit_card",
        otherPaymentMethods: "truemoney,internet_banking",
        onCreateTokenSuccess: nonce => {
          if (nonce.startsWith("tokn_")) {
            form.omiseToken.value = nonce;
          } else {
          }
        }
      });
    }
  }
};
</script>